import React from "react"
import { Link } from "gatsby"
import cx from "classnames"

import Layout from "../components/layout"
import { IMG } from "../const"
import * as spokeStyles from "../assets/styles/spoke.module.scss"
import ContactForm from "../components/contact-form"

const SpokePage = () => {
  return (
    <Layout pageTitle="The Spoke">
      {({
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <main className={spokeStyles.spokeBlock}>
            <section className={spokeStyles.topBlock}>
              <div className={spokeStyles.headerImage}>
                <div className={cx(spokeStyles.container, "container")}>
                  <div className={"row"}>
                    <div
                      className={cx(
                        spokeStyles.leftSide,
                        "col-xs-12 col-sm-12 col-md-8 col-lg-8"
                      )}
                    >
                      <div className={"anim1"}>
                        <picture>
                          <source
                            srcSet={`${IMG.SPOKE}/write.avif`}
                            type="image/avif"
                          />
                          <source
                            srcSet={`${IMG.SPOKE}/write.webp`}
                            type="image/webp"
                          />
                          <img
                            src={`${IMG.SPOKE}/write.png`}
                            alt="The Spoke. A Smarter Way to Search"
                            width="840"
                            height="546"
                            loading="lazy"
                          />
                        </picture>
                      </div>
                    </div>
                    <div
                      className={cx(
                        spokeStyles.rightSide,
                        "col-xs-12 col-sm-12 col-md-4 col-lg-4 contentMiddle"
                      )}
                    >
                      <h1>
                        <em>The Spoke. A Smarter Way to Search</em>
                        <span className={"anim2"}>
                          <img
                            src={`${IMG.SPOKE}/the-spoke-logo-white.svg`}
                            alt="The Spoke. A Smarter Way to Search"
                            width="80"
                            height="39"
                          />
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className={cx(
                spokeStyles.executiveSummary,
                spokeStyles.obliqueTop
              )}
            >
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-5 col-md-4 col-lg-4 contentMiddle"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/group-22.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/group-22.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/group-22.jpg`}
                        alt="The Spoke Executive Summary"
                        width="361"
                        height="621"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12  col-sm-7 col-md-8 col-lg-8"
                    )}
                  >
                    <h2>Executive Summary </h2>
                    <div className={spokeStyles.subTitle}>
                      Executive Summary
                    </div>
                    <div className={spokeStyles.text}>
                      <p>
                        Right now, search results are based on millions of
                        queries and marketing dollars. The problem is that
                        search engine results often don’t fit users’ queries and
                        expectations.
                        <br />
                        However, people mostly get the best recommendations from
                        their friends and relatives. Still, frequently they use
                        ancient methods like texting, emailing and posting on
                        Facebook to gather them, and then they need to figure
                        out where to stream a movie, reserve a table or order
                        takeout.
                      </p>
                      <p>
                        But what will happen when The Spoke comes? The Spoke
                        connects us with recommendations from friends &
                        tastemakers and tells us where to reserve, order or
                        stream them! Users can create an entertainment list,
                        share the list with friends, and follow the lists of
                        other users.
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.streamBlock,
                      "col-xs-12 col-sm-12 col-md-9 offset-md-3 col-lg-9 offset-lg-3"
                    )}
                  >
                    <h3>Now Streaming</h3>
                    <div className={cx(spokeStyles.nowStream, "anim3")}>
                      <picture>
                        <source
                          srcSet={`${IMG.SPOKE}/1_o.avif`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.SPOKE}/1_o.webp`}
                          type="image/webp"
                        />
                        <img
                          src={`${IMG.SPOKE}/1_o.png`}
                          alt="The Spoke Now streaming"
                          width="1154"
                          height="418"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.aboutSpoke}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
                    <h2>About The Spoke</h2>
                    <div className={spokeStyles.subTitle}>About</div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-7 col-lg-8"
                    )}
                  >
                    <div className={spokeStyles.text}>
                      <p>
                        The Spoke is a massive user portal with personal lists
                        of movies, dishes, festivals, fun & games, and other
                        entertainment topics, which users can keep in a safe
                        place and share with their friends. The Spoke’s method
                        of building a search engine is focused on allowing
                        searchers to access personalized content from the people
                        users trust the most. The Spoke works like Spotify,
                        where users can create or follow any list.
                      </p>
                      <p>
                        However, instead of lists of songs, they can follow
                        lists of movies, restaurants, books, and more. After
                        following their friends, their search results for them
                        will be based on their friends’ favorites, and they can
                        watch a movie or reserve a table with one click.
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 col-md-5 col-lg-4"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/group-18.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/group-18.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/group-18.jpg`}
                        alt="About The Spoke"
                        width="470"
                        height="380"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.challengeBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={spokeStyles.subTitle}>Challenge</div>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <h3>Based on your taste</h3>
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/3_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/3_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/3_o.png`}
                        alt="Based on your taste"
                        width="510"
                        height="422"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <h2>Challenge</h2>
                    <div className={spokeStyles.text}>
                      <p>
                        The primary customer need was to create an initial
                        product version that they could use for fundraising and
                        to acquire an initial user base. So, La’Soft had to
                        develop wireframes, offer a UI/UX solution, and build an
                        HTML layout.
                      </p>
                      <p>
                        We provided front-end & back-end development services.
                        An experienced project manager was assigned to lead and
                        coordinate the project so our client would see
                        incredible results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.solutionBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2>Solution</h2>
                    <div className={spokeStyles.subTitle}>Solution</div>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                    )}
                  >
                    <div className={spokeStyles.text}>
                      The Spoke is an expertly developed project. Our goal was
                      to create a UI for a user-friendly service and implement
                      it later. We built the team necessary to meet the client’s
                      needs and filled all the needed positions. TheSpoke has
                      worked directly with our eight very talented and mature
                      employees.
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 col-md-7 col-lg-7"
                    )}
                  >
                    <div className={spokeStyles.text}>
                      When we spoke with the co-founder of The Spoke, Tom Stern,
                      he told us that our team members are technically
                      proficient, picked up the project easily, and outperformed
                      his expectations.
                      <br />
                      Our team helped The Spoke with analysis, creating fresh
                      ideas, and improving the project during the implementation
                      process. Our solution is reliable and will last when the
                      product comes across any difficulties or accelerated
                      growth.
                    </div>
                  </div>
                </div>
                <div className={cx(spokeStyles.quoteRow, "row")}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-4 col-md-5 col-lg-5"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/cli_2.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/cli_2.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/cli_2.png`}
                        alt="Tom Stern, CEO & Co-Founder"
                        width="224"
                        height="320"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-8 col-md-7 col-lg-7"
                    )}
                  >
                    <div className={spokeStyles.text}>
                      <p>
                        “It was hard to find quality engineering talent for the
                        right price. Lots of people pretended they were quality
                        companies, but when we interviewed them, they ended up
                        being underqualified.
                      </p>
                      <p>
                        LaSoft has been great in establishing a long-term
                        partnership and has committed to helping us get to the
                        next level by acquiring top talent if needed.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className={cx(
                spokeStyles.obliqueTop,
                spokeStyles.obliqueBottom,
                spokeStyles.moviesBlock
              )}
            >
              <div className={spokeStyles.moviesBlockImage}>
                <div className={cx(spokeStyles.container, "container")}>
                  <div className={spokeStyles.logoImg} />
                  <div className={"row"}>
                    <div className="col-lg-12">
                      <h2>Movies</h2>
                    </div>
                  </div>
                </div>
                <div className={spokeStyles.moviesMacbook}>
                  <picture>
                    <source
                      srcSet={`${IMG.SPOKE}/007-spoke-opt.avif`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`${IMG.SPOKE}/007-spoke-opt.webp`}
                      type="image/webp"
                    />
                    <img
                      src={`${IMG.SPOKE}/007-spoke-opt.png`}
                      alt="The Spoke: movies"
                      width="1400"
                      height="693"
                      title="The Spoke: movies"
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </section>
            <section className={spokeStyles.searchBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-7 col-lg-7"
                    )}
                  >
                    <h2>Search</h2>
                    <div className={spokeStyles.subTitle}>Search</div>
                    <div className={spokeStyles.text}>
                      <p>
                        Search recommendations from friends and influencers and
                        get a perfect match based on the user's preferences. By
                        typing a movie’s name into a search bar, the user gets a
                        result page with a list of movies that fit their query.
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/5_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/5_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/5_o.png`}
                        alt="Search recommendations"
                        width="1051"
                        height="568"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.watchBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                    )}
                  >
                    <div className={spokeStyles.logoImg} />
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/6_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/6_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/6_o.png`}
                        alt="Watch The Spoke"
                        width="692"
                        height="577"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-1 col-md-6 offset-lg-1 col-lg-6 contentMiddle"
                    )}
                  >
                    <h2>Watch</h2>
                    <div className={spokeStyles.subTitle}>Watch</div>
                    <div className={spokeStyles.text}>
                      User can see all the details about a movie that they are
                      going to watch and find a place for streaming. They help
                      The Spoke become more fluent in searches by rating a film
                      after watching it. It also helps users to receive
                      personalized recommendations.
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.discoverBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <h2>Discover</h2>
                    <div className={spokeStyles.subTitle}>Discover</div>
                    <div className={spokeStyles.text}>
                      <p>
                        With The Spoke there are a bunch of ways to discover new
                        movies to watch. The most effective one is to look for a
                        recommendation from users' close friends. So, to give
                        better advice, The Spoke allows users to discover people
                        followed by their friends.
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <h3>People followed by your friends</h3>
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/7_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/7_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/7_o.png`}
                        alt="Discover"
                        width="1144"
                        height="463"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.followBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                    )}
                  >
                    <h3>People you may know</h3>
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/8_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/8_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/8_o.png`}
                        alt="Follow The Spoke"
                        width="684"
                        height="320"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-1 col-md-6 offset-lg-1 col-lg-6 contentMiddle"
                    )}
                  >
                    <h2>Follow</h2>
                    <div className={spokeStyles.subTitle}>Follow</div>
                    <div className={spokeStyles.text}>
                      <p>
                        The Spoke shows people a list of users that could
                        potentially be interesting for them. When they start
                        following new people and their list, it extends a range
                        of opportunities in searching for the best fits.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.viewBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <h2>View</h2>
                    <div className={spokeStyles.subTitle}>View</div>
                    <div className={spokeStyles.text}>
                      <p>
                        Users can check top lists of movies, follow them for
                        future updates, and filter by score, genre, and
                        streaming services to find the perfect fit.
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-1 col-md-5 offset-lg-1 col-lg-5"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/9_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/9_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/9_o.png`}
                        alt="View"
                        width="1144"
                        height="759"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.createBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/10_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/10_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/10_o.png`}
                        alt="Follow The Spoke"
                        width="877"
                        height="751"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-1 col-md-5 offset-lg-1 col-lg-5 contentMiddle"
                    )}
                  >
                    <h2>Create </h2>
                    <div className={spokeStyles.subTitle}>Create </div>
                    <div className={spokeStyles.text}>
                      <p>
                        Every user can create their own list of movies, sort by
                        category, share with friends and follow other users'
                        lists. Owners can edit or delete their lists.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className={cx(
                spokeStyles.obliqueTop,
                spokeStyles.obliqueBottom,
                spokeStyles.eatBlock
              )}
            >
              <div className={spokeStyles.eatBlockImage}>
                <div className={cx(spokeStyles.container, "container")}>
                  <div className={"row"}>
                    <div className="col-lg-12">
                      <h2>EAT & DRINK</h2>
                    </div>
                  </div>
                </div>
                <div className={spokeStyles.moviesMacbook}>
                  <picture>
                    <source
                      srcSet={`${IMG.SPOKE}/008-spoke_o.avif`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`${IMG.SPOKE}/008-spoke_o.webp`}
                      type="image/webp"
                    />
                    <img
                      src={`${IMG.SPOKE}/008-spoke_o.png`}
                      alt="The Spoke"
                      width="1400"
                      height="718"
                      loading="lazy"
                    />
                  </picture>
                </div>
              </div>
            </section>
            <section className={spokeStyles.lookBlock}>
              <div className={spokeStyles.logoImg} />
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/11_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/11_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/11_o.png`}
                        alt="Look The Spoke"
                        width="1144"
                        height="760"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-1 col-md-6 offset-lg-1 col-lg-6 contentMiddle"
                    )}
                  >
                    <h2>Look </h2>
                    <div className={spokeStyles.subTitle}>Look </div>
                    <div className={spokeStyles.text}>
                      <p>
                        Users can look for a restaurant in the search bar or
                        rely on feedback from their friends. Furthermore, it is
                        helpful to discover a list of users who have become
                        experts in the food domain.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.findBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-3 col-md-7 offset-lg-3 col-lg-7 contentMiddle"
                    )}
                  >
                    <h2>Find </h2>
                    <div className={spokeStyles.subTitle}>Find </div>
                    <div className={spokeStyles.text}>
                      <p>
                        Using a map users can discover the best places close to
                        their location, and see additional info like the place’s
                        exact location or ratings.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3>Close by</h3>
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/12_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/12_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/12_o.png`}
                        alt="Close by"
                        width="1100"
                        height="515"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.shareBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-2 col-md-9 offset-lg-2 col-lg-9 contentMiddle"
                    )}
                  >
                    <h2>Share </h2>
                    <div className={spokeStyles.subTitle}>Share </div>
                    <div className={spokeStyles.text}>
                      <p>
                        Share best places to eat with users, sort them by
                        categories, and get recommendations from friends.
                      </p>
                    </div>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.shareImg,
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/13_o.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/13_o.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/13_o.png`}
                        alt="Share"
                        width="1120"
                        height="298"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.tasteBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 contentMiddle"
                    )}
                  >
                    <h2>Taste</h2>
                    <div className={spokeStyles.subTitle}>Taste</div>
                    <div className={spokeStyles.text}>
                      <p>
                        The Spoke encourages users to rate restaurants to help
                        their friends and improve suggestions for them. Because
                        the more users rate, the smarter The Spoke becomes.
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 offset-md-1 col-md-5 offset-lg-1 col-lg-5"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/14.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/14.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/14.png`}
                        alt="Taste The Spoke"
                        width="872"
                        height="526"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className={spokeStyles.resultInBlockImage}>
                <div
                  className={cx(
                    spokeStyles.obliqueTop,
                    spokeStyles.obliqueBottom,
                    spokeStyles.resultInBlock
                  )}
                >
                  <div className={cx(spokeStyles.container, "container")}>
                    <div className={"row"}>
                      <div className="col-lg-12">
                        <h2>Results</h2>
                        <div className={spokeStyles.text}>
                          <p>
                            Our team was assigned to help The Spoke quickly
                            build the product for a very affordable price. Tom
                            Stern, Co-Founder of The Spoke, said that the
                            La’Soft team’s performance exceeded his
                            expectations, and it was a great reward for us to
                            hear that. We have succeeded in establishing a
                            long-term partnership with The Spoke, and committed
                            to help them get to the next level by acquiring top
                            talent if needed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.devBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    )}
                  >
                    <h2>Developers’ Insights</h2>
                  </div>
                </div>
                <div className={cx(spokeStyles.dev, "row")}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-4 offset-md-1 col-md-3 offset-lg-1 col-lg-3"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/dev_1.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/dev_1.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/dev_1.png`}
                        alt="Oleg Reznichenko, Front-end Developer"
                        width="224"
                        height="320"
                        loading="lazy"
                      />
                    </picture>
                    <div className={spokeStyles.subTitle}>Oleg Reznichenko</div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-8 col-md-8 col-lg-8"
                    )}
                  >
                    <div className={spokeStyles.text}>
                      <p>
                        “I like the idea of The Spoke because it contains all
                        books, restaurants, film services, and more, and will
                        continue to expand its scope. Users don't have to
                        remember a lot of separate services anymore. They just
                        shouldn't forget The Spoke.
                      </p>
                      <p>
                        By migrating to Mobx, we have found a way to optimize
                        the data transfer. We used styled components for style
                        descriptions, so now we have a core of UI components and
                        can easily reuse them where it is needed.
                        <br />
                        Using a lazy loading pattern helped us accelerate a
                        website loading time, which is crucial for users with a
                        bad internet connection. Lazy loading implies dividing
                        code into small parts, which will be uploaded when they
                        are needed. In the future, we will create a native app
                        with the help of React Native. We will be able to reuse
                        much of the code and to speed up the application’s
                        release.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className={cx(spokeStyles.dev, "row")}>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-8 col-md-8 col-lg-8"
                    )}
                  >
                    <div className={spokeStyles.subTitle}>Dmitri Orloff </div>
                    <div className={spokeStyles.text}>
                      <p>
                        “Our main objective as a front-end team was to create a
                        highly interactive, fast and reliable user interface. To
                        accomplish this, we use React for displaying and
                        updating data and MobX for application state management.
                        In addition to rendering performance benefits, these
                        libraries considerably improved loading time and reduced
                        app bundle size by splitting it into route-based
                        reusable components.”
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-4 offset-md-1 col-md-3 offset-lg-1 col-lg-3"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/dev_2.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/dev_2.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/dev_2.png`}
                        alt="Dmitri Orloff, Front-end Developer"
                        width="224"
                        height="320"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
                <div className={cx(spokeStyles.dev, "row")}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-4 offset-md-1 col-md-3 offset-lg-1 col-lg-3"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/dev_3.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/dev_3.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/dev_3.png`}
                        alt="Maryana Shevchuk, UI-UX Designer"
                        width="224"
                        height="320"
                        loading="lazy"
                      />
                    </picture>
                    <div className={spokeStyles.subTitle}>Maryana Shevchuk</div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-8 col-md-8 col-lg-8"
                    )}
                  >
                    <div className={spokeStyles.text}>
                      <p>
                        “The Spoke is an exciting and quite challenging project
                        from a design standpoint. Our goal is to make a super
                        handy product for a generation of users who expect
                        results that match their taste extremely fast. This is
                        why we need to move fast as well. We plan, brainstorm,
                        design, test, talk to users, and start over again. Being
                        very user oriented, we are going to iterate until we
                        meet users’ expectations and build something great.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    )}
                  >
                    <h2 style={{ textAlign: "left" }}>Client’s Feedback</h2>
                  </div>
                </div>
                <div className={cx(spokeStyles.dev, spokeStyles.cli, "row")}>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-8 col-md-8 col-lg-8"
                    )}
                  >
                    <div className={spokeStyles.subTitle}>Brenan Keller </div>
                    <div className={spokeStyles.text}>
                      <p>
                        “LaSoft helped us really revolutionize the way we are
                        developing. Everything from the user interface all the
                        way through development, they had everything covered for
                        us, every resource that we were missing, that we were
                        desperately trying to fill. They are able to fill the
                        gap for us.”
                      </p>
                    </div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-4 offset-md-1 col-md-3 offset-lg-1 col-lg-3"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/cli_1.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/cli_1.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/cli_1.png`}
                        alt="Brenan Keller, CTO & Co-Founder"
                        width="224"
                        height="320"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
                <div className={cx(spokeStyles.dev, spokeStyles.cli, "row")}>
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-4 col-md-3 col-lg-3"
                    )}
                  >
                    <picture>
                      <source
                        srcSet={`${IMG.SPOKE}/cli_2.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`${IMG.SPOKE}/cli_2.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`${IMG.SPOKE}/cli_2.png`}
                        alt="Tom Stern, CEO & Co-Founder"
                        width="224"
                        height="320"
                        loading="lazy"
                      />
                    </picture>
                    <div className={spokeStyles.subTitle}>Tom Stern</div>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-8 col-md-9 col-lg-9"
                    )}
                  >
                    <div className={spokeStyles.text}>
                      <p>
                        “From design to development, again they have taken on
                        the full process. They’ve done such a good job, that
                        we’ve recently raised half a million US dollars to
                        continue development. And we’re now establishing a
                        long-term relationship with our team at La’Soft. We are
                        extremely excited to build this amazing product!”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.videoBlock}>
              <div className={cx(spokeStyles.container, "container")}>
                <div className={spokeStyles.videoWrapper}>
                  <iframe
                    title="spoke-iframe"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/G4jdsXgFKJ0"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </section>
            <section className={spokeStyles.footBlock}>
              <div className={spokeStyles.footBlockImage}>
                <div
                  className={cx(
                    spokeStyles.container,
                    spokeStyles.flexRow,
                    "container"
                  )}
                >
                  <div
                    className={cx(
                      spokeStyles.leftSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <Link to="/">
                      <img
                        src={`${IMG.SPOKE}/lasoft-foot2.svg`}
                        alt="LaSoft"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <div
                    className={cx(
                      spokeStyles.rightSide,
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                    )}
                  >
                    <a href="https://thespoke.co">
                      <img
                        src={`${IMG.SPOKE}/the-spoke-logo-white.svg`}
                        alt="The Spoke A Smarter Way to Search"
                        loading="lazy"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className={spokeStyles.contactForm}>
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </section>
          </main>
        )
      }}
    </Layout>
  )
}

export default SpokePage
